import router from "./router";
import store from "./store";
import nprogress from 'nprogress' // 引入进度条
import 'nprogress/nprogress.css' // 引入进度条样式

router.beforeEach((to, from, next) => {
    nprogress.start()
    if (store.getters.token) {
        next()
    } else {
        if (to.path === '/') {
            next()
        } else {
            next('/')
        }
    }
})
router.afterEach(() => {
    nprogress.done()
})