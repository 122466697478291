import { createRouter, createWebHashHistory } from 'vue-router'

const Layout = () =>
    import ('@/views/Layout')
const Login = () =>
    import ('@/views/login/index')
const Lunbotu = () =>
    import ('@/views/lunbotu/index')
const Home = () =>
    import ('@/views/home/index')
const instruction = () =>
    import ('@/views/instruction/index')
const articleList = () =>
    import ('@/views/articleList/index')

const pms = () =>
    import ('@/views/pms/index')
const hs = () =>
    import ('@/views/hs/index')
const routes = [{
        path: '/',
        component: Login
    },
    {
        path: '/layout',
        component: Layout,
        children: [{
            path: '',
            name: 'dashboard',
            component: Home
        }, {
            path: '/xcx/lbt',
            component: Lunbotu
        }, {
            path: '/xcx/instruction',
            component: instruction
        }, {
            path: '/xcx/articleList',
            component: articleList
        }, {
            path: '/web/pms',
            component: pms
        }, {
            path: '/web/hs',
            component: hs
        }]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router