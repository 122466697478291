<template>
    <button class="lans-button" :class="[size, type]">
        <slot />
    </button>
</template>
<script>
export default {
    name: 'LansButton',
    props: {
        size: {
            type: String,
            default: 'middle'
        },
        type: {
            type: String,
            default: 'primary'
        }
    },
    setup() {
        return {}
    }
}
</script>
<style lang="less" scoped>
@primarycolor: #27BA9B;
@graycolor: #ccc;

.lans-button {
    outline: none;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.mini {
    width: 60px;
    height: 32px;
    font-size: 14px;
}

.small {
    width: 100px;
    height: 32px;
    font-size: 14px;
}

.middle {
    width: 180px;
    height: 50px;
    font-size: 16px;
}

.large {
    width: 240px;
    height: 50px;
    font-size: 16px;
}

.default {
    border-color: #e4e4e4;
    color: #666;
}

.primary {
    background-color: @primarycolor;
    color: white;
}

.gray {
    background-color: @graycolor;
    color: #fff;
}
</style>