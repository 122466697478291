<template>
    <Transition name="down">
        <div class="lans-msg" v-if="visible">
            <span class="msg">
                {{ msg }}
            </span>

        </div>
    </Transition>
</template>
<script>
import { ref, onMounted } from 'vue';
export default {
    name: 'LansMessage',
    props: {
        msg: {
            type: String,
            default: ''
        }
    },
    setup() {
        const visible = ref(false)
        onMounted(() => {
            visible.value = true
        });
        return { visible }
    }
}
</script>
<style lang="less" scoped>
// 动画

.down {
    &-enter {
        &-from {
            transform: translate3d(0, -75px, 0);
            opacity: 0;
        }

        &-active {
            transition: all .4s;
        }

        &-to {
            transform: none;
            opacity: 1;
        }
    }
}

.lans-msg {
    width: 300px;
    height: 50px;
    background-color: white;
    position: fixed;
    top: 25px;
    left: 50%;
    line-height: 50px;
    text-align: center;
    margin-left: -150px;
    border-radius: 4px;

    .msg {}
}
</style>