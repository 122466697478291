import { createStore } from 'vuex'
import user from './modules/user'
import createPersistedState from "vuex-persistedstate";
import getters from './getter';
export default createStore({
    modules: {
        user
    },
    getters,
    plugins: [
        createPersistedState({
            key: 'lans-bms',
            paths: ['user']
        })
    ]
})