<template>
    <div class="lans-confirm" :class="{ show: varshow, }">
        <div class="wrapper" :class="{ show: varshow }">
            <div class="header">
                <svg-icon icon-class="svg-close" font-size="20px" @click="close" />
                <h3>{{ title }}</h3>
            </div>
            <div class="body">
                <svg-icon icon-class="svg-waring" color="#f00" font-size="24px" />
                <span>{{ msg }}</span>
            </div>

            <div class="footer">
                <LansButton size="mini" type="gray" @click="bt_cancel">取消</LansButton>
                <LansButton size="mini" @click="bt_sure">确认</LansButton>
            </div>
        </div>

    </div>
</template>
<script>
import { ref } from 'vue'
import svgIcon from '../SvgIcon/svgIcon.vue'
import LansButton from './lans-button.vue'
export default {
    name: 'confirm',
    props: {
        title: {
            type: String,
            default: '警告'
        },
        msg: {
            type: String,
            default: ''
        },
        sureCallback: {
            type: Function
        },
        cancelCallback: {
            type: Function
        }
    },
    components: {
        svgIcon, LansButton
    },
    setup(props) {
        const bt_cancel = () => {
            props.cancelCallback()
        }
        const bt_sure = () => {
            props.sureCallback()
        }
        const varshow = ref(false)
        const close = () => {
            bt_cancel()
        }
        setTimeout(() => {
            //宏任务--让最后执行--也就是渲染完了再执行
            varshow.value = true
        }, 0);
        return { bt_cancel, bt_sure, varshow, close }
    }
}
</script>
<style lang="less" scoped>
.lans-confirm {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0);

    &.show {
        background-color: rgba(0, 0, 0, .5);
    }

    .wrapper {
        padding: 8px;
        width: 400px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);
        background-color: white;
        border-radius: 6px;

        &.show {
            transition: all .4s;
            transform: translate(-50%, -50%);
        }

        .header {
            position: relative;

            .svg-icon {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;

                &:hover {
                    color: red;
                }
            }
        }

        .body {
            display: flex;
            align-items: center;
            overflow: hidden;
            margin: 12px 0;

            .svg-icon {
                margin: 0 4px;
            }
        }
    }

}

.footer {
    text-align: right;

    .lans-button {
        margin-left: 16px;
    }
}
</style>