import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
import { getTImeStamp } from './auth'

const TimeOut = 3600 // 定义超时时间---1个小时
export const baseURL = "http://www.lansdata.site:8888/"
    // export const baseURL = "http://192.168.0.110:8888/"
const instance = axios.create({
    baseURL,
    timeout: 5000
})

instance.interceptors.request.use(config => {
    if (store.getters.token) {
        if (isCheckTImeOut()) {
            store.dispatch('user/logout')
            console.log('laile111')
            return Promise.reject(new Error('token超时了,请重新登录'))
        }
        config.headers['Authorization'] = `Bear ${store.getters.token}`
    }
    return config
}, err => {
    return Promise.reject(err)
})

instance.interceptors.response.use(res => {
    return res.data
}, err => {
    ElMessage.error({ message: err })
    return Promise.reject(err)
})


export default (url, method, datas) => {
    return instance({
        url,
        method,
        [method.toLowerCase() === 'get' ? 'params' : 'data']: datas
    })
}

function isCheckTImeOut() {
    var currtime = Date.now()
    var timeStamp = getTImeStamp()
    return (currtime - timeStamp) / 1000 > TimeOut
}