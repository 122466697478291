import { logins } from "@/api/user"
import { ElMessage } from "element-plus"
import { setTimeStamp } from "@/utils/auth"
import router from "@/router"
const state = {
    token: '',
    userInfo: {}
}

const mutations = {
    setToken(state, token) {
        state.token = token
    },
    removeToken(state) {
        state.token = null
    },
    setUserInfo(state, result) {
        state.userInfo = result
    },
    removeUserInfo(state) {
        state.userInfo = {}
    }
}

const actions = {
    async login(contex, ubean) {
        const res = await logins(ubean)
        if (res.result) {
            contex.commit('setUserInfo', res.data)
            contex.commit('setToken', res.data.token)
            setTimeStamp()
            ElMessage.success({ message: '登录成功' })
        } else {
            ElMessage.error({ message: res.msg })
        }
    },

    logout(context) {
        context.commit('removeToken')
        context.commit('removeUserInfo')
        router.push('/')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions

}