import Cookies from "js-cookie";


const timeKey = 'lans_login_time'
export const setTimeStamp = () => {
    Cookies.set(timeKey, Date.now())
}

export const getTImeStamp = () => {
    return Cookies.get(timeKey)
}